import * as React from "react"
import { graphql } from "gatsby"
import PostCardModern from "../components/PostCardModern/postCardModern"
import Pagination from "../components/Pagination/pagination"
import Sidebar from "../containers/Sidebar"
import Layout from "../components/layout"
import SEO from "../components/seo"
import _ from 'lodash'
import { BlogPostDetailsWrapper, BlogDetailsContent, PostRow, PostGrid } from "./templates.style"

const Tags = (props: any) => {
  const { data } = props
  const tag = props.pageContext.tag
  const Posts = data.allMarkdownRemark.edges
  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? `/tags/${_.kebabCase(tag)}/page/1`: `/tags/${_.kebabCase(tag)}/page/${(currentPage - 1).toString()}`
  const nextPage = `tags/${_.kebabCase(tag)}/page/${(currentPage + 1).toString()}`
  const PrevLink = !isFirst && prevPage
  const NextLink = !isLast && nextPage

  return (
    <Layout>
      <SEO title={`Trademate Sports Blog | Page ${currentPage}`} />

      <BlogPostDetailsWrapper>
        <BlogDetailsContent>
          <h1>{_.upperCase(tag)}</h1>
          <PostRow>
          {Posts.map(({ node }: any) => {
            //Random Placeholder Color
            const placeholderColors = [
              "#55efc4",
              "#81ecec",
              "#74b9ff",
              "#a29bfe",
              "#ffeaa7",
              "#fab1a0",
              "#e17055",
              "#0984e3",
              "#badc58",
              "#c7ecee",
            ]
            const setColor =
              placeholderColors[
                Math.floor(Math.random() * placeholderColors.length)
              ]
            return (
              <PostGrid>
                <PostCardModern
                  key={node.fields.slug}
                  title={node.frontmatter.externalTitle || node.fields.slug}
                  image={
                    node.frontmatter.cover == null
                      ? null
                      : node.frontmatter.cover.childImageSharp.fluid
                  }
                  coverAlt={node.frontmatter.coverAlt}
                  url={node.fields.slug}
                  description={node.frontmatter.description || node.excerpt}
                  date={node.frontmatter.date}
                  tags={node.frontmatter.tags}
                  placeholderBG={setColor}
                />
              </PostGrid>
            )
          })}
          </PostRow>
          <Pagination
          prevLink={PrevLink}
          nextLink={NextLink}
          currentPage={`${currentPage}`}
          totalPage={`${numPages}`}
        />
        </BlogDetailsContent>
        <Sidebar />
      </BlogPostDetailsWrapper>
    </Layout>
  )
}

export default Tags

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!, $tag: String!) {
    site {
      siteMetadata {
        title
      }
    }
    sitePage {
      path
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD [<span>] MMMM [</span>]")
            externalTitle
            description
            tags
            coverAlt
            cover {
              childImageSharp {
                fluid(maxWidth: 570, maxHeight: 370, quality: 100){
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`




